import httpClient from '@/utilities/http/http-client'

const endpoint = 'api/v1/kontrak'

export default {
  get (idKontrak, params) {
    const queryParams = params ? `?${new URLSearchParams(params).toString()}` : ''
    return httpClient.get(`${endpoint}/${idKontrak}/prosesKpr${queryParams}`)
  },

  show (idKontrak, idKontrakProsesKpr) {
    return httpClient.get(`${endpoint}/${idKontrak}/prosesKpr/${idKontrakProsesKpr}`)
  },

  create (idKontrak, params) {
    return httpClient.post(`${endpoint}/${idKontrak}/prosesKpr`, params)
  },

  update (idKontrak, params) {
    return httpClient.post(`${endpoint}/${idKontrak}/prosesKpr/update`, params)
  },

  delete (idKontrak, idKontrakProsesKpr) {
    return httpClient.delete(`${endpoint}/${idKontrak}/prosesKpr/${idKontrakProsesKpr}`)
  }
}
